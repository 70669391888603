.App {
	height: 100%;
    height: 100vh;
    min-height: 100vh;
    width: 100vw;
}

.Loader {
	display: flex;
	height: 100vh;
	justify-content: center;
	align-items: center;
}

.Notification {
	position: fixed;
	bottom: 20px;
	right: 20px;
}