.Tool {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.Content {
	width: 100%;
	padding: 0 1rem;
}

.List {
	overflow: hidden;
	overflow-y: auto;
	width: 100%;
	max-height: 80vh;
}
