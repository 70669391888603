.Home {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-right: var(--mantine-spacing-xl);
	padding-left: var(--mantine-spacing-xl);
}

.Category {
	background-color: #f5f4ab;
	margin-bottom: var(--mantine-spacing-xs);
	margin-left: var(--mantine-spacing-xs);
}
