.header {
	padding-top: var(--mantine-spacing-md);
	height: rem(60px);
	padding-left: var(--mantine-spacing-xs);
	padding-right: var(--mantine-spacing-xs);
	border-bottom: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.link {
	display: flex;
	align-items: center;
	height: 100%;
	text-decoration: none;
	color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
	font-weight: 500;
	font-size: var(--mantine-font-size-sm);

	@media (max-width: var(--mantine-breakpoint-sm)) {
		height: rem(42px);
		width: 100%;
	}

	@mixin hover {
		background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
	}
}

.linkspacer {
	padding-left: var(--mantine-spacing-xs);
	padding-right: var(--mantine-spacing-xs);
}

.homelink {
	display: flex;
	align-items: center;
	height: 100%;
	text-decoration: none;
	color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
	font-weight: 500;
	font-size: var(--mantine-font-size-sm);

	@media (max-width: var(--mantine-breakpoint-sm)) {
		height: rem(42px);
		width: 100%;
	}

	@mixin hover {
		background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
	}
}
