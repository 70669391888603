.Kills {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.Content {
	width: 100%;
	padding: 0 1rem;
}

.List {
	overflow: hidden;
	overflow-y: auto;
	width: 100%;
	max-height: 80vh;
}

.ExportColumn {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: 1rem;
}

.HoverEffect:hover {
	cursor: pointer;
	color: #fff;
}

.Notification {
	position: fixed;
	bottom: 20px;
	right: 20px;
}